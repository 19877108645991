import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from "../components/seo"

import MainTitle from '../components/MainTitle'
import ColumnsServices from '../components/ColumnsServices'
import ColumnImageRight from '../components/ColumnImageRight'
import ColumnImageLeft from '../components/ColumnImageLeft'
import FaqBlockArea from '../components/FaqBlockArea'
import ArticlesBlockArea from '../components/ArticlesBlockArea'
import ScheduleBlock from '../components/ScheduleBlock'

const IndexPage = ({ data: { directusHome: home, allDirectusQuestoe: questoes, allDirectusArtigo: artigos } }) => (
  <Layout>
    <SEO title="Home" />

      <MainTitle
        content={ home.titulo_principal }
        linkText={ home.titulo_principal_link_text }
        linkUrl={ home.titulo_principal_link }  />

      <ColumnsServices
        content={ home.area_de_servicos }
        coluna_servicos_um={ home.coluna_servicos_um}
        coluna_servicos_um_link_text={ home.coluna_servicos_um_link_text }
        coluna_servicos_um_link={ home.coluna_servicos_um_link }
        coluna_servicos_dois={ home.coluna_servicos_dois }
        coluna_servicos_dois_link_text={ home.coluna_servicos_dois_link_text }
        coluna_servicos_dois_link={ home.coluna_servicos_dois_link }
        coluna_servicos_tres={ home.coluna_servicos_tres }
        coluna_servicos_tres_link_text={ home.coluna_servicos_tres_link_text }
        coluna_servicos_tres_link={ home.coluna_servicos_tres_link }
      />

  
    <ColumnImageRight
      content={ home.conteudo_equipe }
      buttonText={ home.conteudo_equipe_link_text }
      buttonLink={ home.conteudo_equipe_link }
      image={ home.equipe_imagem.localFile.name }
    />
        
    <ColumnImageLeft
      content={ home.conteudo_espaco }
      buttonText={ home.conteudo_espaco_link_text }
      buttonLink={ home.conteudo_espaco_link }
      image={ home.espaco_imagem.localFile.name }
    />

    <FaqBlockArea
      data={ questoes.nodes }
      title={<>Perguntas frequentes <span>(e <em>importantes</em>)</span></>}
      buttonText="Veja todas as perguntas e respostas"
    />

    <ArticlesBlockArea
      data={ artigos.nodes }
      title={<>Notícias <em>&</em> Artigos</>}
      buttonText="Veja mais em nosso Blog"
    />

    <ScheduleBlock
      content={ home.conteudo_agende }
      buttonText={ home.conteudo_agende_link_text}
      buttonLink={ home.conteudo_agende_link } />
  </Layout>
)

export const data = graphql`
  {
    directusHome {
      titulo_principal
      titulo_principal_link
      titulo_principal_link_text
      area_de_servicos
      coluna_servicos_um
      coluna_servicos_um_link
      coluna_servicos_um_link_text
      coluna_servicos_dois
      coluna_servicos_dois_link
      coluna_servicos_dois_link_text
      coluna_servicos_tres
      coluna_servicos_tres_link
      coluna_servicos_tres_link_text
      conteudo_equipe
      conteudo_equipe_link
      conteudo_equipe_link_text
      equipe_imagem {
        localFile {
          name
        }
      }
      conteudo_espaco
      conteudo_espaco_link
      conteudo_espaco_link_text
      espaco_imagem {
        localFile {
          name
        }
      }
      conteudo_agende
      conteudo_agende_link
      conteudo_agende_link_text
    }
    allDirectusQuestoe(limit: 3, sort: {fields: sort}) {
      nodes {
        directusId
        pergunta
        resposta
        imagem {
          localFile {
            name
          }
        }
      }
    }
    allDirectusArtigo(limit: 2, sort: {fields: sort}) {
      nodes {
        id
        titulo
        conteudo
        url
        owner {
          first_name
          last_name
        }
      }
    }
  }
`

export default IndexPage
