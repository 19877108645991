import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from '../Image'

import './column-image-left.scss'

const ColumnImageLeft = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <div className="column-image-left">
            <Fade bottom cascade>
                <div className="column-image-left__container">
                    <div className="column-image-left__image">
                        <div className="image-mask">
                            <Image filename={ props.image } alt="Grupo Reinserir" />
                        </div>
                    </div>

                    <div className="column-image-left__column">
                        <div className="column-image-left__content"
                            dangerouslySetInnerHTML={createMarkup()} />

                        <div className="column-image-left__link">
                            <Link to={ props.buttonLink }>{ props.buttonText }</Link>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    )
}

ColumnImageLeft.propTypes = {
    content: propTypes.string,
    buttonText: propTypes.string,
    buttonLink: propTypes.string,
    image: propTypes.string,
}

export default ColumnImageLeft
