import React from 'react'
import Flip from 'react-reveal/Flip'
import propTypes from 'prop-types'
import { Link } from 'gatsby'

import './schedule-block.scss'

const ScheduleBlock = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <Flip bottom cascade>
            <div className="schedule-block">
                <div className="schedule-block__container">
                    <div className="schedule-block__content"
                            dangerouslySetInnerHTML={createMarkup()} />

                    <div className="schedule-block__link">
                        <Link to={ props.buttonLink }>{ props.buttonText }</Link>
                    </div>
                </div>
            </div>
        </Flip>
    )
}

ScheduleBlock.propTypes = {
    content: propTypes.string,
    buttonText: propTypes.string,
    buttonLink: propTypes.string
}

export default ScheduleBlock
