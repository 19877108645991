import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from '../Image'

import './faq-block.scss'

const FaqBlock = ({ ...props }) => {

    function createMarkup() {
        return { __html: props.resposta.slice(0,200).concat('...') }
    }

    return(
        <div className={ `faq-block faq-block-${ props.pos } `}>
            <Link to={`/perguntas-frequentes#question-${props.directusId}`}>
                <figure>
                    <Image filename={ props.imagem } alt={ `Grupo Reinserir ${props.title}` } />
                </figure>
            </Link>

            <h4>{ props.pergunta }</h4>
            <div className="faq-block__description">
                <div dangerouslySetInnerHTML={createMarkup()} />
            </div>

            <div className="faq-block__button">
                <Link to={`/perguntas-frequentes#question-${props.directusId}`}>Saiba mais</Link>
            </div>
        </div>
    )
}

FaqBlock.propTypes = {
    directusId:propTypes.string,
    image: propTypes.string,
    pergunta: propTypes.string,
    resposta: propTypes.string
}

export default FaqBlock
