import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'
import { Link } from 'gatsby'

import './column-services.scss'

import psicoterapia from './psicoterapia.svg'
import organizacional from './organizacional.svg'
import social from './social.svg'

const ColumnsServices = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    function columnOne() {
        return {__html: props.coluna_servicos_um}
    }

    function columnTwo() {
        return {__html: props.coluna_servicos_dois}
    }

    function columnThree() {
        return {__html: props.coluna_servicos_tres}
    }

    return(
        <div className="columns-services">
            <div className="columns-services__container">
                <div className="columns-services__content"
                        dangerouslySetInnerHTML={createMarkup()} />

                <Fade bottom cascade>
                    <div className="columns-services__columns">
                        { props.coluna_servicos_um &&
                            <div className="columns-services__column">
                                <img src={ psicoterapia } alt="Psicoterapia" />
                                <div dangerouslySetInnerHTML={columnOne()} />
                                <p className="columns-services__link">
                                    <Link to={ props.coluna_servicos_um_link }>{ props.coluna_servicos_um_link_text }</Link>
                                </p>
                            </div>                
                        }

                        { props.coluna_servicos_dois && 
                            <div className="columns-services__column">
                                <img src={ organizacional } alt="Organizacional" />
                                <div dangerouslySetInnerHTML={columnTwo()} />
                                <p className="columns-services__link">
                                    <Link to={ props.coluna_servicos_dois_link }>{ props.coluna_servicos_dois_link_text }</Link>
                                </p>
                            </div>                
                        }

                        { props.coluna_servicos_tres &&
                            <div className="columns-services__column">
                                <img src={ social } alt="Social" />
                                <div dangerouslySetInnerHTML={columnThree()} />
                                <p className="columns-services__link">
                                    <Link to={ props.coluna_servicos_tres_link }>{ props.coluna_servicos_tres_link_text }</Link>
                                </p>
                            </div>                
                        }
                    </div>
                </Fade>
            </div>
        </div>
    )
}

ColumnsServices.propTypes = {
    content: propTypes.string,
    coluna_servicos_um: propTypes.string,
    coluna_servicos_um_link: propTypes.string,
    coluna_servicos_um_link_text: propTypes.string,
    coluna_servicos_dois: propTypes.string,
    coluna_servicos_dois_link: propTypes.string,
    coluna_servicos_dois_link_text: propTypes.string,
    coluna_servicos_tres: propTypes.string,
    coluna_servicos_tres_link: propTypes.string,
    coluna_servicos_tres_link_text: propTypes.string
}

export default ColumnsServices
