import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import ArticleBlock from '../ArticleBlock'

import './articles-block-area.scss'

const NewsBlockArea = ({ ...props }) => {
    return(
        <div className="articles-block-area">
            <Fade bottom cascade>
                <div className="articles-block-area__container">
                    <div className="articles-block-area__title">
                        <h3>{ props.title }</h3>
                        <p className="articles-block-area__link">
                            <Link to="/blog">{ props.buttonText }</Link>
                        </p>
                    </div>

                    <div className="articles-block-area__columns">
                        { props.data.map((item) => (
                            <div key={ item.id }>
                                <ArticleBlock
                                    url={item.url}
                                    title={ item.titulo }
                                    first_name={ item.owner.first_name }
                                    last_name={ item.owner.last_name }
                                    content={ item.conteudo } />
                            </div>
                        ))}
                    </div>
                </div>
            </Fade>
        </div>
    )
}

NewsBlockArea.propTypes = {
    title: propTypes.element.isRequired,
    buttonText: propTypes.string
}

export default NewsBlockArea
