import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import FaqBlock from './FaqBlock'

import './faq-block-area.scss'

const FaqBlockArea = ({ ...props }) => {
    return(
        <div className="faq-block-area">
            
            <Fade bottom cascade>
                <div className="faq-block-area__container">
                    <div className="faq-block-area__title">
                        <h3>{ props.title }</h3>
                        <p className="faq-block-area__link">
                            <Link to="/perguntas-frequentes">{ props.buttonText }</Link>
                        </p>
                    </div>

                    <div className="faq-block-area__columns">
                        { props.data.map((item, index) => (
                            <div key={ item.directusId } className="faq-block-area__column">
                                <FaqBlock
                                    pos={ `pos-${ index }` }
                                    directusId={ item.directusId }
                                    pergunta={ item.pergunta }
                                    resposta={ item.resposta }
                                    imagem={ item.imagem.localFile.name } />
                            </div>
                        ))}
                    </div>
                </div>
            </Fade>
        </div>
    )
}

FaqBlockArea.propTypes = {
    title: propTypes.element.isRequired,
    buttonText: propTypes.string
}


export default FaqBlockArea
